import { Form } from 'connex-cds';
import React from 'react';
import { apps } from '../../api/query-hooks';
import config from './config';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const Apps = () => {
  const listQuery = apps.useListApps();
  const createQuery = apps.useCreateApp();
  const updateQuery = apps.useUpdateApp();

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.VIEW}
      config={config}
      listQuery={listQuery}
      createQuery={createQuery}
      updateQuery={updateQuery}
    />
  );
};

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem } from 'connex-cds';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InitializeApi } from './api/InitializeApi';
import messages from './i18n';
import { Apps } from './views/apps/Apps';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { Companies } from './views/companies/Companies';
import { Landing } from './views/Landing';
import { Profiles } from './views/profiles/Profiles';

API.setSource('admin'); // admin experience api.

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem messages={messages} initialTimeZone="America/Chicago" appId="admin">
            <Routes>
              <Route element={<AuthenticatedRoutes />}>
                <Route path="" element={<Landing />} />
                <Route path="companies/*" element={<Companies />} />
                <Route path="profiles/*" element={<Profiles />} />
                <Route path="apps/*" element={<Apps />} />
              </Route>
            </Routes>
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
